/* Estilos generales para la barra de chat */
.chat-bar {
  display: flex;
  flex-direction: column;
  background-color: #3d3d3d;
  color: white;
  padding: 10px 10px 0 10px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  resize: none; /* Evita el redimensionamiento */
  margin: 0;
}

/* Estilos para el contenedor de mensajes */
.chat-messages {
  position: relative;
  overflow-y: auto; /* Scroll vertical */
  overflow-x: hidden; /* Oculta el scroll horizontal */
  background-color: #3d3d3d;
  max-width: 960px;
  max-height: calc(100vh - 150px); /* Ajusta este valor según el espacio disponible */
  cursor: default;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Estilos para los mensajes */
.chat-message {
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 5px; /* Ajustado para reducir el espacio entre mensajes */
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.2; /* Ajuste del interlineado */
}

/* Estilo adicional para mensajes de usuario */
.chat-message.user-message {
  align-self: flex-end;
  font-size: 15px;
  background-color: #4f4f4f;
  color: #f6f6f6;
  max-width: 90%; /* Ancho máximo para los mensajes de usuario */
}

/* Estilo adicional para mensajes de modelo */
.chat-message.model-message {
  align-self: flex-start;
  font-size: 15px;
  background-color: #3d3d3d;
  color: #f6f6f6;
}

/* Estilos para mensajes de modelo tipo simblock */
.chat-message.model-message {
  align-self: flex-start;
  font-size: 15px;
  background-color: #3d3d3d;
  color: #f6f6f6;
}

.system-progress-message {
  background-color: #f0f0f0;
  color: #555;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  font-style: italic;
}

.progress-update-message {
  font-size: 10px;
}

.system-notification-message {
  background-color: #eaf5ff; /* Light blue background */
  color: #0b61a4; /* Dark blue text */
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}


.simblock-container {
  display: flex;
  flex-direction: column;
}

.simblock-field {
  margin-bottom: 15px;
}

.simblock-field h4 {
  margin: 0 0 5px 0;
  text-transform: uppercase;
  font-weight: bold;
}

/* Container for the code block and the copy button */
.code-block-container {
  position: relative;
  margin-bottom: 15px;
}

/* Style the SyntaxHighlighter code block */
.custom-syntax-highlighter {
  background-color: black !important;
  padding: 20px 10px 10px 10px !important;
  border-radius: 5px !important;
  color: #f6f6f6 !important;
  font-family: 'Courier New', Courier, monospace !important;
  overflow-x: auto; /* Allow horizontal scroll for long lines */
  position: relative;
}

/* Hover effect for the Copy button */
.copy-button:hover {
  background-color: #666666;
}

/* Adjust the pre tag inside SyntaxHighlighter */
.custom-syntax-highlighter pre {
  white-space: pre-wrap !important;
  word-break: break-word !important;
  margin: 0; /* Remove default margin */
}

/* Style for the "Copied" state */
.copy-button.copied {
  background-color: #d80d0d; /* Slightly darker to show change */
  color: #8f8f8f; /* Change text color to indicate copied status */
}

/* Estilos para el contenedor de entrada de chat */
.chat-input-container {
  position: relative; /* Necesario para posicionar la imagen dentro del contenedor */
  padding: 1px;
  background-color: #262626;
  border-radius: 12px;
  display: flex;
  align-items: center;
  max-width: 720px;
  width: 100%;
  min-height: 52px;
  max-height: 180px; /* Altura máxima para el chat-input */
  box-sizing: border-box;
  margin: 0;
  justify-content: space-between;
}

/* Estilos para la miniatura de imagen cargada */
/*.image-preview {
  position: absolute;
  top: -90px; /* Ajusta la posición vertical por encima del chat-input
  left: 10px; /* Ajusta la posición horizontal 
  width: 80px; /* Ancho máximo de la miniatura 
  height: 80px; /* Altura máxima de la miniatura 
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* Fondo blanco para la miniatura 
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Sombra alrededor de la miniatura 
  z-index: 1; /* Asegura que la imagen esté sobre otros elementos 
}*/

/* Estilos para la imagen dentro de la miniatura */
.image-thumbnail {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Ajuste para cubrir el área de la miniatura */
}

/* Estilos para el área de texto */
.chat-input {
  background-color: #262626;
  font-size: 16px;
  color: #f6f6f6;
  padding: 10px;
  border: none;
  margin: 0 10px;
  resize: none; /* Evita el redimensionamiento manual */
  min-height: 40px;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  outline: none; /* Elimina el contorno de enfoque */
  line-height: 1.5;
  display: flex;
  align-items: center; /* Centra los elementos verticalmente */
}

/* Estilos para el botón de enviar */
.send-button {
  margin-right: 7px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  padding: 0;
  background-color: #d1d1d1;
  color: #3d3d3d;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease; /* Suaviza la transición de color */
}

.send-button:hover:not(.disabled) {
  background-color: #b0b0b0; /* Cambia el color al pasar el ratón */
}

/* Estilos para el botón deshabilitado */
.send-button.disabled {
  background-color: #5d5d5d;
  color: #262626;
  cursor: not-allowed;
}

/* Icono dentro del botón */
.send-button i {
  font-size: 24px;
}

/* Rotación específica del icono de flecha */
.bi-arrow-return-right {
  transform: rotate(-90deg); /* Gira el icono */
}

/* Estilos para el botón de imagen */
.image-button {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  padding: 0;
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.image-button:hover:not(.disabled) {
  background-color: #3d3d3d; /* Cambia el color al pasar el ratón */
}

/* Estilos para el botón de imagen deshabilitado */
.image-button.disabled {
  background-color: #5d5d5d;
  color: #262626;
  cursor: not-allowed;
}

/* Icono dentro del botón de imagen */
.image-button i {
  font-size: 24px;
}

/* Estilos para el botón down */
.down-button {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  padding: 0;
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.down-button:hover:not(.disabled) {
  background-color: #3d3d3d;
}

.down-button i {
  font-size: 24px;
}

/* Estilos para el botón stop */
.stop-button {
  width: 40px;
  height: 40px;
  margin-right: 5px; /* Espacio entre el botón stop y el botón down */
  border-radius: 10px;
  padding: 0;
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-left: 10px; /* Espacio entre el chat input y el botón stop */
}

.stop-button:hover:not(.disabled) {
  background-color: #3d3d3d;
}

.stop-button i {
  font-size: 24px;
}

/* Estilos para el spinner dentro del botón de enviar */
.send-button-spinner {
  background-color: #262626;
  color: #d1d1d1;
  border-width: 2px;
  padding: 0;
  margin-right: 7px;
}

.send-button .send-button-spinner {
  margin: 0 auto;
}
.hidden {
  display: none; /* Oculta completamente el botón */
}

.chat-message ul,
.chat-message ol {
  margin: 0;
  padding-left: 20px; /* Ajusta el sangrado de la lista */
}

.chat-message li {
  display: list-item; /* Asegura que los li se comporten como elementos de lista */
  list-style-type: disc; /* Tipo de viñeta, puedes cambiarlo según necesites */
  align-items: flex-start; /* Alinea el contenido al inicio */
  margin: 0;
  padding: 0;
  flex-direction: column; /* Asegura que los hijos se apilen verticalmente */
}

/* Estilos para párrafos dentro de li */
.chat-message li p {
  margin: 0;
  padding: 0;
  display: block; /* Asegura que p sea un elemento de bloque */
}

/* Si los li tienen display:flex, asegura que flex-direction es columna */
.chat-message li {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em; /* Reduce el espacio entre elementos de lista */
}
.chat-message p {
    margin-top: 0;
    margin-bottom: 0;
}

.ai-disclaimer {
  margin-top: 2px;
  font-size: 12px;
  color: #b0b0b0;
  text-align: center;
  max-width: 720px;
  width: 100%;
  box-sizing: border-box;
}
