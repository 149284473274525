.elements {
  border-top: 1px solid #262626;
  border-bottom: 1px solid #262626;
  width: 177.25px;
  background-color: #3d3d3d;
  overflow-y: auto;
  overflow-x: hidden; /* Oculta el scrollbar horizontal */
  transition: width 0.3s ease-in-out;
  height: 100%; /* Hace que el contenedor ocupe toda la altura disponible */
}

.elements.collapsed {
  width: 25px; /* Reduce el ancho cuando está colapsado */
}

.item-list-title {
  font-size: 14px;
  color: #b0b0b0;
  font-weight: normal;
  text-align: left;
  margin: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es muy largo */
}

.element-item {
  background-color: #3d3d3d;
  color: #f6f6f6;
  border: none;
  cursor: pointer;
  padding: 2px 2px 2px 0px;
  font-size: 14px;
  margin-bottom: 0px;
  width: calc(100% - 0px); /* Ajusta el ancho del elemento para que encaje en el contenedor */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es muy largo */
}

.element-item:hover {
  background-color: #262626;
}

.element-item .element-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Agrega puntos suspensivos si el texto es muy largo */
  flex-grow: 1;
  margin-right: 0px; /* Asegura que los tres puntos estén visibles */
  max-width: calc(100% - 0px); /* Limita el tamaño máximo del texto al contenedor */
}

.element-item.selected {
  background-color: #a8d3e8; /* Celeste para los elementos seleccionados */
  color: #262626;
}

.element-actions {
  display: flex;
  align-items: center;
  margin-left: auto; /* Asegura que el menú esté a la derecha */
}

.element-actions svg {
  margin-left: 10px;
  cursor: pointer;
}
