body {
    background-color: #0e0e0e;
}


.terms-of-use-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 0px 0px 0px;
    background-color: #0e0e0e;
    color: #ffffff;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .terms-of-use-content h1,
  .terms-of-use-content h2,
  .terms-of-use-content h3,
  .terms-of-use-content h4 {
    color: #ffffff;
    margin-top: 30px;
  }
  
  .terms-of-use-content p {
    margin-bottom: 15px;
  }
  
  .terms-of-use-content ol,
  .terms-of-use-content ul {
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  .terms-of-use-content li {
    margin-bottom: 10px;
  }
  
  .terms-of-use-content a {
    color: #0066cc;
    text-decoration: none;
  }
  
  .terms-of-use-content a:hover {
    text-decoration: underline;
  }
  