/* Estilos generales */
.group-focus-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  transition: opacity 0.3s ease-in-out;
}

.group-focus {
  position: fixed;
  top: 0;
  right: -60%;
  max-width: 1000px;
  width: calc(100% - 60px); /* Ancho ajustado para pantallas pequeñas */
  height: 100%;
  background-color: #262626;
  z-index: 2001;
  transform: translateX(0);
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.group-focus.open {
  right: 0;
}

.group-focus.closing {
  right: -60%;
}

.group-focus-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3d3d3d;
  padding: 10px;
  border-bottom: 1px solid #262626;
}

.group-focus-header-left {
  width: 10%;
  display: flex;
  align-items: center;
}

.group-focus-header-left input {
  background-color: #3d3d3d;
  font-size: 18px;
  width: 200px;
  margin-left: 10px;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  color: white;
  border-bottom: 1px solid #4f4f4f;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #b0b0b0 !important;
  margin-left: 5px;
}

.main-layout {
  display: flex;
  width: 100%;
  height: 100%;
}

.predefined-buttons {
  max-width: 20%;
  background-color: #3d3d3d;
  color: #b0b0b0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #262626;
}

.predefined-buttons h4 {
  padding: 0px 6px 0px 5px; 
  font-size: 20px;
  margin-top: 0;
  color: #b0b0b0;
}

.predefined-buttons button {
  color: #b0b0b0;
  border: none;
  cursor: pointer;
}

.predefined-buttons button:hover {
  background-color: #262626;
}

.predefined-buttons button.active {
  background-color: #a8d3e8;
  color: #262626;
}

.folder-title {
  cursor: pointer;
  user-select: none;
  margin: 1px 1px;
  align-items: center; /* Centra verticalmente los elementos */
  justify-content: center; /* Centra horizontalmente los elementos */
  transition: background-color 0.3s ease;
  
}

.folder > button {
  padding: 10px;
  background: none;
  border: none;
  color: #b0b0b0;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
  justify-content: space-between;
}

.contenedor-nodes {
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 0 auto; /* Centra el elemento horizontalmente */
}

.contenedor-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1px;
}

.contenedor-a {
  width: 100%;
  text-align: center;
  max-width: 220px; /* Limita el ancho máximo */
  background-color: #3d3d3d;
  padding: 10px;
  border-right: 1px solid #262626;
}


.contenedor-b {
  width: 100%;
  background-color: #3d3d3d;
  color: #b0b0b0;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.contenedorB-titulo {
  margin-bottom: 10px;
}

.contenedorB-contenido {
  max-height: 200px; /* Ajusta el valor según sea necesario */
  overflow-y: auto;
}

.contenedor-a h4,
.contenedor-b h4,
.contenedor-c h3 {
  font-size: 20px;
  color: #b0b0b0;
}

.contenedor-a-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto; /* Deja que el contenido dicte el alto */
  max-height: 200px;
  text-align: center;
}

.contenedor-b p {
  margin: 0;
  
}

.contenedor-c {
  flex-grow: 1; /* Ocupa el espacio vertical disponible */
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #3d3d3d;
  color: #b0b0b0;
  overflow: hidden; /* Evita que aparezca un scrollbar en el contenedor */
}


.contenedor-c textarea {
  width: 100%;
  flex-grow: 1; /* Ajusta para ocupar todo el espacio vertical de contenedor-c */
  min-height: 0;
  max-height: 78%;
  background-color: #262626;
  padding: 7px;
  resize: none;
  border: 1px solid transparent;
  outline: none;
  border-radius: 3px;
  overflow-y: auto; /* Solo el textarea tiene scrollbar si es necesario */
  margin-bottom: 0;
}


.contenedor-c textarea::placeholder {
  color: #6d6d6d; /* Cambia #b0b0b0 al color que prefieras */
  opacity: 1; /* Asegura que el color se vea completamente (1 es el valor máximo) */
}


.contenedor-c .char-count {
  display: block;
  text-align: right;
  font-size: 12px;
  color: #b0b0b0;
  margin-top: 5px;
}

.save-button i {
  font-size: 24px;
}

.save-button:disabled {
  background-color: grey;
  cursor: not-allowed;
  position: relative;
}

.save-button:disabled::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 3000;
  display: none;
  text-align: right;
}


/* Estilo para los nombres de los puertos */
.port-label {
  font-size: 8px;
  font-weight: bold;
  fill: #333; /* Color del texto */
  text-anchor: middle; /* Centrará el texto en el nodo */
}

/* Estilo de la flecha */
.port-label-arrow {
  stroke: #4f4f4f !important; /* Color de la flecha */
  stroke-width: 1px;
}

.port-label-background {
  fill: #e0e0e0; /* Color de fondo del rectángulo */
  stroke: #333; /* Borde del rectángulo */
  stroke-width: 0.5px;
  opacity: 0.5; /* Transparencia del fondo */
}

/* Controla el tamaño del SVG usando el contenedor */
.svg-instance {
  width: 100%; /* Se adapta al ancho del contenedor */
  height: 100%; /* Se adapta al alto del contenedor */
  max-width: 200px; /* Tamaño máximo */
  max-height: 200px;
  overflow: visible;
  padding: 10px; /* Agrega espacio alrededor del contenido */
  background-color: #262626;
  border-radius: 3px;
}

/* Opción para mantener la relación de aspecto */
.svg-instance {
  aspect-ratio: 1 / 1; /* Mantiene la proporción cuadrada */
  width: 100%; /* Llena el contenedor */
  height: auto; /* Escala automáticamente */
}

.contenedor-a .svg-instance circle {
  fill: #d1d1d1; /* Cambia este color según sea necesario */
}

.contenedor-a .svg-instance line {
  stroke: #888888; /* Cambia este color para las líneas generales */
  stroke-width: 1px;
}

.folder-title i {
  font-size: 13px; /* Tamaño de los iconos */
  color: #b0b0b0;     /* Color inicial */
  margin-right: 2px; /* Espacio entre el icono y el texto */
  transition: color 0.3s ease; /* Transición suave en el cambio de color */ 
}

.predefined-button {
  display: flex;
  align-items: center;
  padding: 1px;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.folder {
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  display: block; /* Cambia a block si está en inline-block o flex */
}

.button-content {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap; /* Permite que el contenido se ajuste en varias líneas si es necesario */
}

@media (max-width: 695px) {
  .button-content {
    flex-wrap: wrap;
  }
}

.button-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 4px;
  margin-right: 8px;
  box-sizing: border-box;
}

.thumbnail-svg {
  width: 100%;
  height: 100%;
  display: block; /* Asegura que el SVG ocupe todo el contenedor */
  transform: translate( -2%, 10%);
}

.thumbnail-edge {
  stroke: #ffffff; /* Cambia este color según tus necesidades */
}

.thumbnail-node {
  fill: #ffffff; /* Cambia este color según tus necesidades */
}

.predefined-button.active .thumbnail-node {
  fill: #262626; /* Color para nodos cuando el botón está activo */
}

.predefined-button.active .thumbnail-edge {
  stroke: #262626; /* Color para bordes cuando el botón está activo */
}
