.simu-list {
  flex: 1;
  overflow-y: auto;
  color: #d1d1d1;
}

.simulator-item {
  background-color: #3d3d3d;
  margin-bottom: 1px;
  padding: 5px;
}

.simulator-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3d3d3d;
}

.simulator-name {
  font-size: 14px;
  color: #d1d1d1;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Ajusta el tamaño máximo del texto */
}

.simulator-buttons {
  display: flex;
  align-items: center;
}

.simulator-buttons .separator {
  color: #d1d1d1;
  margin: 1px;
}

/* Botones de la lista de simuladores */
.edit-button,
.simulator-buttons .delete-button,
.minimize-button {
  background: none !important;
  border: none;
  color: #d1d1d1;
  cursor: pointer;
  font-size: 16px;
  margin-left: 0px;
  padding: 1px 5px;
  
}

.edit-button:hover,
.simulator-buttons .delete-button:hover,
.minimize-button:hover {
  background-color: #4f4f4f;
  color: #f6f6f6;
  font-size: 16px;
  padding: 1px 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.simulator-item-body {
  margin-top: 10px;
  text-align: center;
}

.run-button {
  background-color: #262626;
  border: none;
  font-size: 14px;
  display: inline-flex; /* Cambiar de flex a inline-flex para que el tamaño se ajuste al contenido */
  color: #d1d1d1;
  cursor: pointer;
  padding: 5px 10px;
  width: 209px;
  justify-content: center;
  border-radius: 5px;
}

.run-button:hover {
  background-color: #5d5d5d;
}

/* Estilos del popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  padding-top: 20px;
}

.popup-content {
  font-size: 16px;
  color: #f6f6f6;
  width: 400px;
  background-color: #3d3d3d;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.cancel-button {
  background-color: #6d6d6d;
  color: #b0b0b0;
}

.cancel-button:hover {
  background-color: #4f4f4f;
}

/* Diferenciación del botón de eliminación en el popup */
.popup .delete-button {
  background-color: #ba6666;
  color: #b0b0b0;
}

.popup .delete-button:hover {
  background-color: #8c4449;
}

.simulator-group {
  display: flex;
  background-color: #262626;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: none !important;
}

/* Estilos para el contenedor de la imagen generada */
.simulator-image-container {
  display: flex;
  flex-direction: column;
  background-color: #3d3d3d;
  border-bottom: 5px solid #444444;
}

.simulator-image-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #232323;
  padding: 5px;
  border-bottom: 1px solid #444;
  border-radius: 5px 5px 0 0;
}

.simulator-image-label {
  font-size: 14px;
  color: #b0b0b0;
}

.download-button {
  background: none;
  border: none;
  color: #b0b0b0;
  cursor: pointer;
  font-size: 17px;
}

.download-button:hover {
  color: #d1d1d1;
}

.simulator-image {
  width: 100%;
  max-width: 100%;
  margin-top: 1px;
}

/* Estilos para el contenedor del texto generado */
.simulator-output-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  background-color: #3d3d3d;
}

.simulator-output-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #232323;
  padding: 5px;
  border-bottom: 1px solid #444444;
  border-radius: 5px 5px 0 0;
}

.simulator-output-label {
  font-size: 14px;
  color: #b0b0b0;
}

.copy-button {
  background: none;
  border: none;
  color: #b0b0b0;
  cursor: pointer;
  font-size: 17px;
}

.simulator-output {
  width: 100%;
  height: 100px;
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  resize: none;
  padding: 5px;
  outline: none; /* Quita el borde blanco en foco */
}

.copy-button,
.download-text-button {
  background: none;
  border: none;
  color: #b0b0b0;
  cursor: pointer;
  font-size: 17px;
  outline: none; /* Quitar borde de enfoque */
  box-shadow: none; /* Asegurar que no haya sombra de fondo */
}

.copy-button:hover,
.download-text-button:hover {
  color: #d1d1d1;
  background: none;
  outline: none;
  box-shadow: none;
  background: none !important;
}

.simulator-buttons-container {
  display: flex;
  gap: 5px; /* Espacio entre los botones */
}

.copy-button:focus,
.download-text-button:focus {
  outline: none;
  box-shadow: none;
}
