.diagram-scroll-area {
  position: relative;
  flex: 1;
  overflow: auto; /* Permite el desplazamiento horizontal y vertical */
  background-color: #0e0e0e;
  flex-direction: column;
  border: 1px solid #262626;
  max-width: 100%;
  max-height: 100%;
  cursor: default; /* Cursor por defecto */
  user-select: none; /* Evita la selección de texto */
}

.diagram {
  width: 2500px; /* Ancho fijo del diagrama */
  height: 900px; /* Alto fijo del diagrama */
  cursor: default; /* Cursor por defecto */
}

.diagram line {
  stroke: black; /* Cambiar el color de las líneas */
  stroke-width: 1px; /* 0.5*/
}

/* Ensure no global styles are overriding the specific shape edges styles */
.shape-edge {
  stroke: white !important;
  stroke-width: 1px !important;
}

.element-content {
  display: block; /* Maneja el flujo de texto como un bloque */
  width: 100%;
  height: 100%;
  background-color: transparent; /* Sin fondo */
  border: none; /* Sin borde */
  color: #f6f6f6; /* Color del texto */
  font-size: 12px; /* Tamaño del texto */
  user-select: none; /* Evitar la selección de texto */
  overflow: hidden; /* Oculta el texto que desborda */
  text-overflow: ellipsis; /* Agrega puntos suspensivos al desbordar */
  white-space: normal; /* Permite el salto de línea */
  word-break: break-all; /* Permite que las palabras se rompan en cualquier lugar */
  line-height: 1.2; /* Ajusta la altura de línea para acomodar texto */
  max-height: 2.4em; /* Limita la altura máxima a dos líneas de texto */
  box-sizing: border-box; /* Asegura que padding y border se incluyan en el tamaño total */
  text-align: left; /* Alineación del texto a la izquierda */
  padding: 2px; /* Opcional: añade un poco de espacio alrededor del texto */
}

/* Define styles for the hovered nodes */
.node-point {
  transition: fill 0.3s ease; /* Smooth transition for the fill color */
  fill: transparent
}

.node-point.hovered {
  fill: #a8d3e8; /* Change color to red when hovered */
  stroke: transparent;
  stroke-width: 1;
}


.diagram.dragging {
  cursor: move; /* Cursor de movimiento */
}

.arrow {
  stroke: #f6f6f6 !important;
  stroke-width: 2;
}

/* Nueva clase para las flowlines seleccionadas */
.arrow.selected {
  stroke: #a8d3e8 !important; /* Cambiar a amarillo cuando esté seleccionada */
  stroke-width: 2; /* Aumentar el grosor cuando esté seleccionada */
}

.flowline-label-group {
  cursor: pointer;
}

.flowline-label-group:hover .flowline-label-bg {
  fill: #3d3d3d;
}

.flowline-label-group:hover .flowline-label {
  fill: #f6f6f6;
}

.flowline-options {
  visibility: hidden;
}

.flowline-label-group:hover .flowline-options {
  visibility: visible;
}

.flowline-label {
  font-size: 12px;
  fill: black;
  user-select: none;
  pointer-events: none;
}

.flowline-label-bg {
  transition: fill 0.3s ease;
}

.flowline-label-group:hover .flowline-label {
  transition: fill 0.3s ease;
}

.flowline-text-container {
  display: inline-block;
  max-width: 100%; /* Limita el ancho máximo al contenedor */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  color: #f6f6f6;
  background-color: transparent;
  padding: 0px 5px;
  border-radius: 3px;
  user-select: none;
  transition: background-color 0.3s, color 0.3s;
}

.flowline-label-group:hover {
  background-color: #3d3d3d;
  color: #f6f6f6;
}

.flowline-text-container:hover {
  background-color: #262626;
  color: #f6f6f6;
}

.port-name {
  font-size: 12px;
  color: #454545;
  background-color: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px;
  user-select: none;
}