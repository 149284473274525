.simulator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: #262626;
  border-bottom: 1px solid #3d3d3d;
}

.left-buttons, .right-buttons {
  display: flex;
  align-items: center;
}

.simulator-header .collapse-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapse-button:hover {
  background-color: #3d3d3d;
}

.build-simulator-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.build-simulator-button:hover {
  background-color: #3d3d3d;
}

.new-simulator-button {
  height: 40px;
  /*border-radius: 5px;*/
  font-size: 16px;
  background-color: #262626;
  color: white;
  border: none;
  padding: 0 8px;
  cursor: pointer;
}

.new-simulator-button:hover {
  background-color: #3d3d3d;
}
