/* Estilos para el contenedor mini-header */
.mini-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: #3d3d3d;
}

.left-buttons, .right-buttons {
  display: flex;
  align-items: center;
}

/* Estilos para el botón de crear elemento */
.create-element-button {
  height: 40px;
  /*border-radius: 5px;*/
  font-size: 16px;
  background-color: #3d3d3d;
  color: white;
  border: none;
  padding: 0 8px;
  cursor: pointer;
  /*margin-right: 31.83px;*/
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; /* Esto agrega las elipsis cuando el texto es demasiado largo */
  max-width: 120px;
}

.create-element-button:hover {
  background-color: #0056b3;
}

.create-element-button.collapsed {
  /*margin-right: 14px;*/
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #3d3d3d;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  padding: 0 8px;
  align-self: flex-end;
  z-index: 2;
}

.create-element-button i {
  font-size: 24px; /* Tamaño del icono */
}

.create-element-button:hover {
  background-color: #4f4f4f; /* Color de fondo al pasar el cursor */
}

/* Estilos para el botón de colapsar/desplegar elementos */
.toggle-elements-button {
  border-radius: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #3d3d3d;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  padding: 0 8px;
  align-self: flex-end;
  z-index: 2;
}


.toggle-elements-button:hover {
  background-color: #4f4f4f; /* Color de fondo al pasar el cursor */
  color: #d1d1d1;
  border-radius: 0px;
}

.toggle-elements-button:active {
  border-radius: 0px;
  background-color: #4f4f4f !important; /* Color de fondo al pasar el cursor */
  color: #d1d1d1 !important;
}

/* Estilos para el botón de la papelera */
.trash-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;
  color: #d1d1d1; /* Color del icono */
  border: none;
  cursor: pointer;
  padding: 0 8px;
  align-self: flex-end;
  z-index: 2;
}

.trash-button:hover {
  background-color: #4f4f4f;
}

.trash-button i {
  font-size: 24px; /* Tamaño del icono */
}

.separator {
  margin: 0px 0px 0px 0px;
  color: #d1d1d1;
}

/* Estilos para el botón de bloqueo/desbloqueo */
.lock-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;
  color: #d1d1d1;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
}

.lock-button:hover {
  background-color: #4f4f4f; /* Oscurecer el color en hover */
}

.lock-button.active {
  background-color: #28a745;
  color: white;
}

.lock-button::after {
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  /*border-radius: 5px;*/
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}

.lock-button:hover::after {
  content: attr(title);
  opacity: 1;
  visibility: visible;
}

/* Estilos para el botón de guardar */
.save-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  background-color: #3d3d3d !important;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.save-button:hover {
  background-color: #4f4f4f !important;
}

.save-button i {
  font-size: 24px; /* Tamaño del icono */
}

/* Estilos para el botón de grid */
.grid-button {
  width: 40px;
  height: 40px;
  /*border-radius: 5px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d3d3d;
  color: #d1d1d1; /* Color del icono */
  border: none;
  cursor: pointer;
  padding: 0 8px;
  align-self: flex-end;
  z-index: 2;
}

.grid-button:hover {
  background-color: #4f4f4f;
}

.grid-button.active {
  background-color: #d1d1d1;
  color: #3d3d3d;
}

.grid-button i {
  font-size: 24px; /* Tamaño del icono */
}

/* Estilos para el popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Centrar verticalmente */
  z-index: 9999;
  padding-top: 20px; /* Espacio desde la parte superior */
}

.popup-content {
  font-size: 16px ;
  color: #f6f6f6;
  width: 400px; /* Ancho del popup */
  background-color: #3d3d3d;
  padding: 10px; /* Espaciado interno */
  /*border-radius: 5px;*/
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left; /* Alinear texto a la izquierda */
  margin: 0px 0px 5px 0px; /* Espaciado entre líneas */
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0; /* Remover padding para que no afecte el layout */
  color: black; /* Color del icono de cerrar */
}

.popup-buttons {
  display: flex;
  justify-content: flex-end; /* Agrupar botones a la derecha */
  margin-top: 10px;
}

.cancel-button, .delete-button {
  font-size: 16px;
  padding: 3px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px; /* Espacio entre los botones */
}

.cancel-button {
  background-color: #6d6d6d;
  color: white;
}

.cancel-button:hover {
  background-color: #4f4f4f;
}

.delete-button {
  background-color: #ba6666;
  color: white;
}

.delete-button:hover {
  background-color: #8c4449;
}
