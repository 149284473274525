.App {
  display: flex;
  background-color: #f0f0f0;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* General styles for the app-wide layout */
.main-content {
  display: flex;
  height: 100vh; /* Ensures full-height layout */
  margin-top: 0; /* Removes top margin */
}

/* Scrollbar styling globally */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

/* Scrollbar styles for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #262626 #6d6d6d;
}

/* Utility classes */
.hidden {
  display: none;
}