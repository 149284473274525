/* dropdownmenu.css */

#dropdown-basic-button::after {
    display: none !important;
  }
  
  .menu-button::after {
    display: none !important;
  }
  
  .dropdown-menu.menu-clicked {
    background-color: #3d3d3d;
    border-color: #4f4f4f;
    border-radius: 5px;
    font-size: 14px;
    width: 247px; /* Ancho específico para menú clicado */
    padding: 5px;
    position: absolute;
    z-index: 2050;
  }

  /* Estilos para el menú desplegable */
  .dropdown-menu {
    background-color: #3d3d3d;
    border-color: #4f4f4f;
    border-radius: 3px;
    padding: 2px 5px 2px 5px; /* Espacio alrededor del texto */
    position: absolute;
    z-index: 2050;
    min-width: fit-content; /* Ancho basado en el contenido */
    max-width: 90vw; /* Para evitar desbordamiento en pantallas pequeñas */
    white-space: nowrap; /* Evita el salto de línea en el texto */
  }
  
  .menu-item-clicked {
    background-color: #2a2a2a; /* Cambia el color de fondo si lo necesitas */
    color: #ffffff; /* Ajusta el color del texto */
    font-weight: none; /* Opcional, para destacar */
    font-size: 14px !important;
    padding: 5px 15px !important;
  }

  .dropdown-header {
    text-align: center;
    padding: 10px;
    color: #d1d1d1;
  }
  
  .greeting {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .dropdown-body {
    padding: 10px;
    color: #333;
  }
  
  .email {
    margin: 0;
    font-size: 14px;
  }
  
  .dropdown-divider {
    margin: 0;
    border-top: 1px solid #6d6d6d;
  }
  
  .dropdown-item {
    width: auto;
    font-size: 13px;
    border-radius: 3px;
    background-color: #3d3d3d;
    color: #d1d1d1;
    padding: 3px 5px;
    min-width: 100px; /* Ajusta el ancho mínimo deseado */
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  
  .dropdown-item:hover {
    background-color: #e7e7e7;
    color: #262626
  }
  
  #dropdown-basic-button {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: #262626;
    color: #d1d1d1;
    border: none;
    cursor: pointer;
    padding: 0px 8px;
  }
  
  #dropdown-basic-button:hover {
    background-color: #454545;
  }
  
  .modal-content {
    background-color: #3d3d3d; /* Cambia a tu color preferido */
    color: #d1d1d1;
  }
  
  .modal-header, .modal-body {
    background-color: #3d3d3d; /* Coincide con el fondo del modal */
    color: #d1d1d1;
  }
  
  .plan-container {
    background-color: #3d3d3d; /* Fondo del contenedor dentro del modal */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 100%;
    max-width: 384px;
    margin: 0 auto;
  }
  
  .plan-container h5 {
    font-size: 18px;
    margin-top: 10px;
    color: #d1d1d1;
  }
  
  .plan-container p {
    font-size: 16px;
    color: #d1d1d1;
    margin: 10px 0;
  }
  
  .plan-features {
    list-style: none;
    padding: 0;
    font-size: 14px;
    text-align: left;
    color: #d1d1d1;
  }
  
  .plan-features li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .plan-features li i {
    margin-right: 8px;
    color: #28a745;
  }
  
  .cancel-subscription {
    font-size: 12px;
    text-decoration: underline;
    color: #007bff;
    cursor: pointer;
    margin-top: 15px;
  }

  .modal-backdrop.show {
    background-color: #000; /* Negro sólido */
    opacity: 1;
  }

  .buy-button {
    background-color: #007bff; /* Cambia el color si es necesario */
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    margin: 15px 0;
    cursor: pointer;
  }

  .buy-button:hover {
    background-color: #0056b3; /* Un tono más oscuro en hover */
  }