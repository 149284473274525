.multimodal-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative; /* Añadir posición relativa */
  width: 100%;
  height: 100%; /* Ajusta la altura si es necesario */
}

.elements-diagram-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto; /* Asegura que el desplazamiento vertical no sea permitido aquí */
  height: calc(100vh - 60px); /* Ajuste según la altura de otros componentes */
  position: relative; /* Añadir posición relativa */
}

/* Asegurarse de que los elementos hijos no oculten el dropdown */
.project-item-wrapper {
  position: relative;
  z-index: 1; /* Asegura que los elementos hijos no se superpongan */
}

.portal-dropdown-menu {
  position: absolute;
  z-index: 1050; /* Asegura que el menú esté por encima de otros elementos */
  background-color: #454545; /* Color de fondo del menú */
  border-radius: 5px; /* Radio de borde para el menú */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Sombra para el menú */
  top: 0; /* Ajustar según sea necesario */
  left: 0; /* Ajustar según sea necesario */
}

.loader {
  position: absolute; /* Relativo al contenedor padre */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra el loader en su contenedor padre */
  z-index: 9999; /* Asegura que el loader esté por encima de todo en su contenedor */
  pointer-events: none; /* Evita que interfiera con otros elementos interactivos */
  background-color: #3d3d3d;
  border-radius: 50%; /* Hace que el loader sea un círculo perfecto */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border {
  width: 34px;
  height: 34px;
  border-width: 0.3rem;
  color: #b0b0b0;
}

.spinner-border .sr-only {
  display: none;
}
