.header-container {
    width: 100%;
    background-color: rgba(14, 14, 14, 0.8); /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #262626;

    /* Nuevas propiedades */
    position: fixed; /* Fija el contenedor en la parte superior */
    top: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Aplica el desenfoque */
    -webkit-backdrop-filter: blur(10px); /* Soporte para navegadores webkit */
}




.homepage-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Espacio entre el logo, título y enlaces */
    width: 100%;
    max-width: 1055px;
    height: 50px;
    padding: 0 13px;
}

.header-left {
    display: flex;
    align-items: center;
}

.header-title {
    font-size: 18px; /* Cambia este valor al tamaño deseado */
    font-weight: 400;
    color: #ffffff; /* Cambia el color del título */
}

.header-links {
    display: flex;
    margin-left: auto; /* Alinea los enlaces hacia la derecha */
    padding: 0px 0px 0px 20px; /* Espacio entre el título y los enlaces */
    font-size: 13px;
    font-weight: normal;
}

.header-link {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    padding: 5px 10px; /* Espacio dentro de cada enlace */
}

.header-link:hover {
    color: #aaa; /* Color para el efecto hover */
}

.header-logo {
    padding: 5px;
    width: 32px;
    height: auto;
    margin-right: 10px;
    color: #b8b8b8; /* Cambia este color al que desees para las líneas */
}
