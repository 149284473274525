/* Main container for the Chat component */
.Chat {
    display: flex;
    background-color: #888888;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  
  .main-content {
    display: flex;
    height: 100vh; /* Ensures full-height layout */
    margin-top: 0; /* Removes top margin */
  }
  
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  
  /* Multimodal group container styles */
  .multimodal-group-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
  }
  
  .multimodal-group {
    flex: 1; /* Takes remaining space */
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  /* Simulator group styles */
  .simulator-group {
    width: 250px; /* Fixed width for the SimulatorGroup */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  /* ChatBar styles */
  .chat-messages {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
  }
  
  .chat-message {
    padding: 5px;
    border-bottom: 1px solid #555;
  }
  
  .chat-input-container {
    display: flex;
    align-items: center;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  /* Custom scrollbar styling */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }
  
  /* Firefox scrollbar styles */
  * {
    scrollbar-width: thin;
    scrollbar-color: #262626 #6d6d6d;
  }
  