.homepage-footer {
    background-color: #0e0e0e;
    color: #fff;
    padding: 20px 20px 0px 20px;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 10px;
  }
  
  .footer-section h4 {
    font-size: 13px;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 8px;
  }
  
  .footer-section a {
    font-size: 13px; /* Cambia este valor al tamaño deseado */
    color: #b8b8b8;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    color: #fff;
  }
  
  .footer-bottom {
    text-align: center;
    padding: 10px;
    font-size: 12px;
    border-top: 1px solid #444;
    margin-top: 20px;
  }
  
  .footer-section p {
    font-size: 13px; /* Cambia este valor al tamaño deseado */
    color: #b8b8b8; /* Asegúrate de que el color coincida con el estilo deseado */
    margin-bottom: 5px;
  }