.simulator-group {
  display: flex;
  flex-direction: column;
  background-color: #262626;
  overflow: hidden;
  border-left: 1px solid #262626;
  border-bottom: 1px solid #262626;
  position: relative;
}

.simulator-group.collapsed {
  width: 40px  !important;
}

.simulator-header {
  flex-shrink: 0;
}

.simu-list {
  flex: 1;
  overflow-y: auto;
}

/* Estilos para el resizer */
.simulator-group .resizer {
  width: 5px;
  cursor: ew-resize;
  position: absolute;
  left: -2.5px; /* Para que el área de agarre sea más accesible */
  top: 0;
  bottom: 0;
  z-index: 100;
}

.simulator-group .resizer:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Estilos para la imagen responsiva en el grupo de simuladores */
.simulator-group .simulator-group {
  margin-top: 15px;
  text-align: center;
  width: 100%; /* El grupo toma todo el ancho disponible */
  max-width: 100%; /* Asegura que el grupo no exceda el ancho disponible */
  height: auto;
}

.simulator-group .placeholder-image {
  width: 100%;      /* La imagen toma todo el ancho del contenedor */
  height: auto;     /* Mantiene la proporción al ajustar la altura */
  max-width: 100%;  /* Evita que la imagen se expanda más allá del contenedor */
  border-radius: 5px;
  object-fit: cover; /* La imagen se ajusta para cubrir el espacio, manteniendo proporciones */
  transition: all 0.3s ease; /* Suaviza los cambios de tamaño */
}
