.simulator-focus-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2000;
    transition: opacity 0.3s ease-in-out;
  }
  
  .simulator-focus {
    position: fixed;
    top: 0;
    right: -60%; /* Start off-screen */
    width: 60%;
    height: 100%;
    background-color: #262626;
    z-index: 2001;
    transform: translateX(0);
    transition: right 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .simulator-focus.open {
    right: 0; /* Slide in when open */
  }
  
  .simulator-focus.closing {
    right: -60%; /* Slide out when closing */
  }
  
  .simulator-focus-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #3d3d3d;
    padding: 10px;
  }
  
  .simulator-focus-header-left {
    width: 10%;
    display: flex;
    align-items: center;
  }
  
  .simulator-focus-header-left input {
    background-color: #3d3d3d;
    width: 200px;
    margin-left: 10px;
    border: none;
    padding: 0;
    box-shadow: none;
    outline: none;
    color: white;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
  }
  
  .simulator-focus-body {
    height: 100%;
    max-width: 600px;
    font-size: 16px;
    margin: 20px auto;
    color: #b0b0b0;
    background-color: #262626;
    padding: 10px;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
  
  .simulator-focus-body h3.simulator-focus-title {
    color: #b0b0b0;
    margin-bottom: 10px;
    font-size: 19px;
  }
  
  .save-button i {
    font-size: 24px;
  }
  
  .save-button:disabled {
    background-color: rgb(212, 15, 15);
    cursor: not-allowed;
    position: relative;
  }
  
  .save-button:disabled::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 3000;
    display: none;
    text-align: right;
  }
  
  .save-button:disabled:hover::after {
    display: block;
  }
  
  textarea::placeholder,
  input::placeholder {
    color: #b0b0b0;
  }
  
/* Estilos para el editor de código */
.code-editor {
    background-color: #2d2d2d;
    color: white;
    font-family: 'Fira Code', 'Fira Mono', monospace;
    font-size: 14px;
    border: 1px solid #4f4f4f;
    border-radius: 5px;
    min-height: 400px;
    overflow-y: auto;
    padding: 10px;
  }
  
  /* Asegúrate de que el código resaltado se muestre correctamente */
  .code-editor pre {
    margin: 0;
  }
  
  .simulator-focus-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .copy-code-button {
    background: none;
    border: none;
    color: #b0b0b0;
    cursor: pointer;
    font-size: 18px;
  }
  
  .copy-code-button:hover {
    color: #d1d1d1;
  }