/* HistoryGroup.css */

/* Otros estilos previos */

.history-group {
  width: 250px;
  background-color: #262626;
  color: white;
  transition: width 0.3s ease, max-height 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.history-group.collapsed {
  width: 68px;
}

.custom-padding {
  padding: 14px;
}

.history-content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history-content {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.history-content.collapsed-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.new-project-button {
  background-color: #3d3d3d;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px 5px 5px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.new-project-button:hover {
  background-color: #454545;
}

.project-group {
  margin-bottom: 20px;
}

.project-item-wrapper {
  position: relative;
}

.project-item {
  background-color: #262626;
  color: #f6f6f6;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 0px;
  width: 100%;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease;
}

.project-item .project-name {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 14px;
}

.project-item.hover {
  background-color: #454545;
}

.project-item-dots {
  display: none;
  position: absolute;
  right: 10px;
  color: #b0b0b0;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 5px 5px 5px 5px;
}

.project-item-dots:hover {
  background-color: #262626;
}

.project-item-dots, .project-item-dots:hover {
  display: inline;
  color: #fff;
}

.collapse-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #262626;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  padding: 0px 8px;
  align-self: flex-end;
  z-index: 2;
}

.collapse-button:hover {
  background-color: #454545;
  color: #d1d1d1;
}

.button-container {
  transition: flex-direction 0.3s ease, justify-content 0.3s ease;
}

.button-container.d-flex.flex-column {
  align-items: center;
}

.deeplayered-text {
  color: #d1d1d1;
  font-size: 19px;
  margin-left: 15px;
  align-self: flex-start;
  transition: opacity 0.3s ease, max-width 0.3s ease;
  align-items: center;
  display: flex;
  height: 40px;
}

.deeplayered-text.collapsed {
  opacity: 0;
  max-width: 0;
  overflow: hidden;
  white-space: nowrap;
}

.d-flex.align-items-center {
  justify-content: space-between;
  margin-left: 0;
}

.date-display {
  font-size: 14px;
  color: #b0b0b0;
}

.three-dots-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background: none;
}

.three-dots-toggle::after {
  display: none !important;
}
