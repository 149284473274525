/* ElementItem.css */

.element-item {
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.element-item.hovered {
  background-color: #262626;
}

.element-icon {
  margin-right: 7px; /* Aumenta el espacio entre el icono y el texto */
  flex-shrink: 0; /* Asegura que el icono no se reduzca */
  padding: 0.5px; /* Espacio interno alrededor del icono */
  background-color: transparent; /* Fondo del icono */
  width: 24px;
  height: 24px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}


.thumbnail-svg {
  width: 100%;
  height: 100%;
}

.element-text {
  flex-grow: 1; /* Permite que el texto ocupe el espacio restante */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.element-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.element-three-dots-toggle {
  background: none;
  border: none;
  color: #888888;
  padding: 0;
  display: none;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 5px 5px 5px 5px;
}

.element-three-dots-toggle:hover {
  background-color: #3d3d3d;
}

.element-item:hover .element-three-dots-toggle,
.element-item.hovered .element-three-dots-toggle {
  display: flex;
}

.element-three-dots-toggle:hover {
  color: #e4e4e4;
}

.element-item-dots .dropdown-menu {
  background-color: #262626;
  border: 1px solid #444;
}

.element-item-dots .dropdown-item {
  color: #f6f6f6;
}

.element-item-dots .dropdown-item:hover {
  background-color: #444;
}

.element-item.selected .thumbnail-node {
  fill: #262626; /* Color para nodos cuando está seleccionado */
}

.element-item.selected .thumbnail-edge {
  stroke: #262626; /* Color para bordes cuando está seleccionado */
}
