.focus-flowline-wrapper .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro semitransparente */
  z-index: 999; /* Asegúrate de que esté debajo del popup */
}

.focus-flowline-wrapper .focus-flowline {
  position: absolute; /* Cambiado a absolute */
  /* Se eliminan top, left y transform */
  background-color: #262626;
  padding: 0px;
  z-index: 1002;
  width: 270px !important;
}

.focus-flowline-wrapper .focus-flowline-content {
  background-color: #3d3d3d;
  border-radius: 0px 0px 0px 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
}

.focus-flowline-wrapper .group-focus-header {
  display: flex;
  border-radius: 0px 0px 0px 0px;
  justify-content: space-between;
  align-items: center;
  background-color: #262626;
  padding: 10px;
}

.focus-flowline-wrapper .group-focus-header i {
  cursor: pointer;
  font-size: 20px;
  color: #d1d1d1;
}

.focus-flowline-wrapper .group-focus-header i:hover {
  color: #f6f6f6; /* Cambiar el color al pasar el mouse */
}

.focus-flowline-wrapper .focus-flowline-body {
  display: flex;
  flex-direction: column; /* Asegura que los elementos se alineen verticalmente */
  justify-content: center;
  align-items: center;
  padding: 10px; /* Agrega un padding interno para un mejor diseño */
}

.focus-flowline-wrapper .flowline-label {
  color: #b0b0b0; /* Color por defecto del texto */
  margin-bottom: 10px;
  font-size: var(--title-font-size, 15px); /* Tamaño de fuente por defecto, personalizable */
  text-align: left; /* Alineación del texto a la izquierda */
  width: 100%; /* Asegura que el texto ocupe todo el ancho disponible */
}

.focus-flowline-wrapper .flowline-name-input {
  width: 100%;
  padding: 5px;
  background-color: #262626;
  color: #d1d1d1;
  font-size: 14px;
  font-family: Arial, sans-serif;
  overflow-y: hidden; /* Ocultar barra de desplazamiento */
  resize: none; /* No permitir el redimensionamiento */
  box-sizing: border-box;
  min-height: 50px; /* Altura mínima del textarea */
  border: none; /* Color del borde */
  border-radius: 3px;
}

.focus-flowline-wrapper .flowline-name-input:focus {
  outline: none; /* Elimina el borde predeterminado de enfoque */
  box-shadow: none; /* Elimina cualquier sombra que aparezca al enfocar */
}
