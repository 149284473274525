:root {
  --page-background-color: #0e0e0e;
}

.page-container {
  background-color: #0e0e0e;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.homepage-body {
  margin-top: 50px;
  width: 100%;
  max-width: 1500px;
  background-color: #0e0e0e;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.intro-section {
  text-align: center;
  margin: 140px auto 150px auto;
  max-width: 730px;
}

.intro-title {
  color: #fff;
  font-size: 16px;
  margin-bottom: 20px;
}

.intro-subtitle {
  color: #f6f6f6;
  font-size: 50px;
  margin-bottom: 20px;
}

.intro-text {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 30px;
}

.intro-button {
  background-color: #f6f6f6;
  color: #0e0e0e;
  padding: 7px 14px;
  font-size: 18px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.intro-button:hover {
  background-color: #d1d1d1;
}

/* Estilos para la descripción del recuadro */
.box-description {
  color: #d1d1d1;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

/* Estilos comunes para los recuadros */
.box {
  border-radius: 5px;
  border: 1px solid #7f7f7f;
  padding: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 150px;
}

.box-image {
  width: 100%;
  height: auto;
}

/* Estilos comunes para las minipáginas */
.mini-page {
  border-radius: 3px;
  color: #fff;
  background-color: #ffffff;
  padding: 0px 0px 20px 0px;
  margin-bottom: 0px;
}

/* ------------------------------
   Estilos para el Recuadro 1
---------------------------------*/
.box1 .mini-page-header,
.box2 .mini-page-header {
  border-radius: 3px 3px 0px 0px;
  background-color: #3b757f;
  height: 40px;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  
}

.box1 .company-info {
  display: flex;
  align-items: center;
}

.box1 .company-logo,
.box2 .company-logo {
  width: 20px;
  height: 50px;
  margin-right: 10px;
}

.box1 .mini-page-title,
.box2 .mini-page-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
}

.box1 .mini-page-body {
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

.box1 .product-card {
  padding: 15px;
  width: 30%;
  margin-bottom: 20px;
  background-color: #5d5d5d;
  text-align: center;
}

.box1 .product-image {
  width: 100%;
  height: auto;
}

.box1 .product-title {
  font-size: 20px;
  margin: 15px 0 10px 0;
}

.box1 .product-description {
  font-size: 14px;
}

/* Estilos adicionales para el Recuadro 1 */
.box1 .additional-info {
  display: none; /* Oculto si no se utiliza */
}

/* ------------------------------
   Estilos para el Recuadro 2
---------------------------------*/
.box2 .company-info {
  display: flex;
  align-items: center;
}

.box2 .header-buttons {
  display: flex;
  gap: 10px;
}

.box2 .mini-page-body {
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.box2 .product-card {
  padding: 15px;
  width: 45%;
  background-color: #92cace;
  text-align: center;
}

.box2 .product-image {
  width: 100%;
  height: auto;
}

.box2 .product-description {
  font-size: 14px;
}

/* Estilos para las opciones del producto en Recuadro 2 */
.box2 .product-options {
  width: 45%;
  padding: 16px;
  background-color: #ffffff;
  color: #000000;
}

.box2 .product-options p {
  font-size: 20px;
  margin-bottom: 15px;
}

/* Estilos para los dropdowns */
.box2 .product-options .dropdown {
  margin-bottom: 0px;
}

.box2 .product-options label {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
}

.box2 .product-options select {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.box2 .buy-button {
  padding: 5px 10px;
  background-color: #5faab1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: default;
  font-size: 16px;
}

.box2 .buy-button:hover {
  background-color: #5faab1;
}

/* Estilos adicionales para el Recuadro 2 */
.box2 .additional-info {
  display: flex;
  background-color: #0e0e0e;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 800px;
  margin: 0 auto;
  padding: 15px;
}

.box2 .page-iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* ------------------------------
   Estilos para el Recuadro 3
---------------------------------*/
.box3 .box-image {
  width: 100%;
  height: auto;
}

/* ------------------------------
   Estilos para el logo y los iconos
---------------------------------*/
.company-logo .cls-1 {
  fill: #1a2c32 !important; /* Color deseado */
  stroke-width: 1px !important;
}

.header-buttons {
  display: flex;
  gap: 15px;
}

.header-icon {
  font-size: 24px;
  color: #ffffff;
}

.bi.bi-cart.header-icon {
  color: #5faab1; /* Cambia este color al que prefieras */
}

/* ------------------------------
   Estilos para las nuevas líneas de texto y botones
---------------------------------*/
/* Estilos para la línea de texto sobre mini-page-body */



.mini-page-body-text p {
  margin-bottom: 0px;
  display: flex;          /* Utiliza Flexbox para manejar el diseño */
  gap: 40px;              /* Espaciado horizontal entre los elementos */
  justify-content: center; /* Centra el contenido horizontalmente */
}

@media (max-width: 930px) {
  .mini-page-body-text p {
    display: none; /* Oculta el texto cuando la pantalla es menor de 800px */
  }
}

/* Estilos para la sección additional-info-header */
.box2 .additional-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Si quieres que los elementos internos estén separados */
  width: 95%;
  padding: 10px 20px 10px 20px;
  background-color: #0e0e0e;
  color: #f6f6f6;
  margin: 0 auto; /* Centra el bloque dentro de su contenedor */
}

.box2 .additional-info-header p {
  margin: 0;
  font-size: 25px;
}

/* Estilos para el botón reload-button */
.box2 .reload-button {
  padding: 5px 10px;
  background-color: #f6f6f6;
  color: #0e0e0e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.box2 .reload-button i {
  margin-right: 8px;
}

.box2 .reload-button:hover {
  background-color: #d1d1d1;
}

.mini-page-textdirection {
  margin-left: 20px;
  color: #262626;
  font-size: 11px;
}

.intro-text2 {
  font-size: 18px;           /* Tamaño de la fuente */
  color: #ffffff;            /* Color del texto */
  text-align: left;        /* Alineación centrada del texto */
}

.contact-form {
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  min-width: 200px;
  margin-right: 20px;
}

.form-group label {
  color: #b0b0b0; /* Cambia #333 por el color que desees */
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  background-color: #0e0e0e; /* Cambia #f9f9f9 por el color que desees */
  color: #fff;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #7f7f7f; /* Cambia #ccc por el color que desees */
}

button[type="submit"] {
  padding: 5px 10px;
  background-color: #f6f6f6;
  color: #0e0e0e;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
}

button[type="submit"]:hover {
  background-color: #b0b0b0;
}

.intro-subtitle-coming {
  color: #f6f6f6;
  font-size: 50px;
  margin-bottom: 20px;
  margin-top: 130px;
}

.button-go button[type="button-go"] {
  padding: 5px 15px;
  background-color: #e0e0e0;
  color: #1a1a1a;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-go button[type="button-go"]:hover {
  background-color: #a8a8a8;
  transform: scale(1.05);
}

.button-go button[type="button-go"]:active {
  background-color: #8f8f8f;
  transform: scale(0.95);
}
