.models-container {
    position: relative;
    display: flex;
    justify-content: space-between; /* Alinea los botones a los extremos */
    align-items: center;
    width: 100%;
}


.models-button {
    background-color: #3d3d3d;
    color: #d1d1d1;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer; /* Puntero de mano */
    display: flex;
    align-items: center;
    align-self: flex-start; /* Asegura que se alinee al inicio */
    transition: background-color 0.3s ease;
}


.models-button i {
    margin-left: 8px;
    font-size: 12px;
}

.models-button:hover {
    background-color: #4f4f4f;
}

.models-dropdown {
    background-color: #3d3d3d;
    border: 1px solid #4f4f4f;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    top: 100%;
    min-width: 150px;
    z-index: 10;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.models-dropdown-item {
    font-weight: bold;
    padding: 5px 10px;
    color: #d1d1d1;
    font-size: 13px;
    border-radius: 3px;
    transition: background-color 0.2s;
    cursor: pointer; /* Puntero de mano */
}

.models-dropdown-item:hover {
    background-color: #5d5d5d;
}

.models-dropdown-title { 
    font-size: 13px;
    padding: 0px 10px;
    color: #d1d1d1;
    margin-bottom: 5px;
}

.expand-button {
    background-color: #3d3d3d;
    color: #d1d1d1;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
}

.expand-button:hover {
    background-color: #4f4f4f;
}

.expand-button i {
    font-size: 16px;
}

.chat-bar {
    /* Tus estilos existentes */
    height: 40%;
}

.chat-bar.expanded {
    height: 100%;
}